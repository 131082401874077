Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";
exports.passwordError = "Please enter password"
exports.labelLegalText = "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";
exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.contentTypeApiAddDetail = "application/json";
exports.apiMethodTypeAddDetail = "POST";
exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.emailRegistrationAPiEndPoint = "account_block/accounts/email_otp";
exports.emailOTPConfirmationAPiEndPoint = "account_block/accounts/email_confirmations";
exports.passwordRegistrationAPiEndPoint = "account/accounts";
exports.emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
exports.otpRegex = /^\d{0,6}$/
exports.passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
exports.getTermsAndConditionApiEndPoint = "bx_block_terms_and_conditions/terms_and_conditions"
exports.getApiMethod = "GET";
exports.getNewPrivacyPolicyApiEndPoint = "bx_block_content_management/privacy_policies"
exports.translations = {
  fr: {
    maxOtpAttemptsErr: "Nombre maximal de tentatives OTP atteint. Veuillez réessayer après 5 minutes ",
    passwordNotMatchErr: "Le mot de passe ne correspond pas",
    enterPassPh : "Enter Password",
    emailInputPlaceholder: "Adresse e-mail",
    welcomeMsg: 'Bienvenue chez Patientiste',
    sixDigitOtp: "Veuillez saisir l'OTP à 6 chiffres et vérifier votre adresse e-mail",
    loginWithPass: "Connectez-vous avec mot de passe",
    and: 'et',
    confirmNewPassPh: "Confirmer le nouveau mot de passe",
    emailBoxHeader: 'Veuillez entrer votre adresse e-mail pour continuer',
    passwordMatchErr: "Le mot de passe ne correspond pas",
    setPassMsg: "Veuillez entrer votre nouveau mot de passe pour réinitialiser le mot de passe",
    changePassBtn: 'Changer le mot de passe',
    language: "Langue",
    tnc: "En vous connectant, vous acceptez les",
    didNotGetOtp: "Je n'ai pas obtenu l'OTP?",
    passwordCreatedMsg: 'Mot de passe créé avec succès',
    editEmailMsg: " Modifier l'adresse e-mail",
    prp: 'Politique de confidentialité',
    EnterNewPassPh: "Entrez un nouveau mot de passe",
    terms: 'Conditions générales',
    passwordSyntaxErr: "Password must contain 1 uppercase, 1 lowercase, 1 special character, 1 numeric digit, and be 8-15 characters long!",
    confirmPassErr: "please enter confirm password",
    continueBtnText: 'Continuer',
    otpExpiredErr: "Cet OTP est expiré ! Veuillez demander un nouvel OTP.",
    invalidEmailErr: "Veuillez entrer une adresse e-mail valide",
    pageHeader: 'Essais scientifiques testant l\'efficacité et la sécurité des vaccins',
    submitBtn: 'Soumettre',
    password15CharErr: 'Le mot de passe doit comporter au maximum 15 caractères !',
    resendOtp: "Renvoyer OTP",
    newLoginMsg : "Connectez-vous avec un nouveau mot de passe",
    wrongOtpEr: "Please enter a valid 6-digit OTP",
    setPass: "Définir votre mot de passe",
    pepErr: 'Veuillez entrer le mot de passe',
    createPasswordBtn: "Changer le mot de passe",
    unverifiedEmailErr: "L'e-mail n'est pas vérifié, veuillez saisir à nouveau",
    forgotPass : "Mot de passe oublié?",
    resetPass : "Réinitialisez votre mot de passe",
    passResetMsg : 'Mot de passe créé avec succès',
    resetPassMsg : "Veuillez entrer votre nouveau mot de passe pour réinitialiser le mot de passe.",
    login : "Se connecter",
    verifyBtn : "Vérifier",
    forgotPassLoginMsg : "Veuillez entrer votre adresse e-mail pour OTP vérification",
    },
  en: {
   
    confirmPassErr: 'please enter confirm password',
    maxOtpAttemptsErr: "Maximum OTP attempts reached. Please try again after 5 minutes",
    emailBoxHeader: 'Please enter your email address to continue',
    enterPassPh : "Enter Password",
    welcomeMsg: 'Welcome to Patientist', 
    pageHeader: 'Scientific trials testing vaccines for effectiveness and safety',  
    tnc: "By signing in, you're agreeing to Medical Trail App's",
    language: 'Language',
    wrongOtpEr: 'Please enter a valid 6-digit OTP',
    changePassBtn: 'Change Password',
    resendOtp: 'Resend OTP',
    setPassMsg: 'Please enter your new password for reset the password',
    sixDigitOtp: 'Please enter the 6 Digit OTP and verify your',
    passwordCreatedMsg: 'Password Created Successfully',
    continueBtnText: 'Continue',
    editEmailMsg: 'Edit Email Address',
    emailInputPlaceholder: 'Email Address',
    terms: 'Terms and Conditions',
    passwordMatchErr: 'Password do not match',
    passwordSyntaxErr: "Password must contain 1 uppercase, 1 lowercase, 1 special character, 1 numeric digit, and be 8-15 characters long!",
    invalidEmailErr: 'Please enter a valid email address',
    unverifiedEmailErr: 'Email is not verified, please enter once again',
    didNotGetOtp: "Didn’t got the OTP?",
    pepErr: 'Please enter password',
    loginWithPass: 'Login with password',
    confirmNewPassPh: 'Confirm New Password',
    passwordNotMatchErr: 'Password doesn’t not match',
    otpExpiredErr: 'This OTP is expired! Kindly request a new OTP.',
    EnterNewPassPh: 'Enter New Password',
    prp: 'Privacy Policy',
    password15CharErr: 'Password must be at most 15 characters long!',
    setPass: 'Set Your Password',
    submitBtn: 'Submit',
    createPasswordBtn: 'Create Password',
    and: 'and',
    forgotPass : "Forgot Password?",
    login : "Login",
    forgotPassLoginMsg : 'Please enter your email address for OTP verification',
    verifyBtn : "Verify",
    passResetMsg : 'Password reset successfully',
    newLoginMsg : "Login with New Password",
    resetPass : "Re-set Your Password",
    resetPassMsg : "Please enter your new password for reset the password",


  },
  es: {
    setPassMsg: "Por favor ingrese su nueva contraseña para restablecer el contraseña",
    confirmNewPassPh: "Confirmar nueva contraseña",
    setPass: "Establece tu contraseña",
    enterPassPh : "Introducir la contraseña",
    sixDigitOtp: 'Ingrese la OTP de 6 dígitos y verifique sudirección de correo electrónico',
    passwordMatchErr: "La contraseña no coincide",
    tnc: "Al iniciar sesión, aceptas los",
    maxOtpAttemptsErr: "Se alcanzó el número máximo de intentos de OTP. Inténtelo de nuevo después de 5 minutos",
    resendOtp: "Reenviar OTP",
    createPasswordBtn: "cambiar la contraseña",
    passwordCreatedMsg: 'Contraseña creada exitosamente',
    invalidEmailErr: "Por favor ingrese una dirección de correo electrónico válida",
    EnterNewPassPh: "Ingrese nueva clave",
    unverifiedEmailErr: "El correo electrónico no está verificado, por favor ingresa una vez más",
    loginWithPass: "Iniciar sesión con nueva contraseña",
    continueBtnText: 'Continuar',
    pageHeader: 'Ensayos científicos que prueban la eficacia y seguridad de las vacunas',
    changePassBtn: 'cambiar la contraseña',
    prp: 'Política de privacidad',
    pepErr: 'Por favor, ingrese contraseña',
    emailInputPlaceholder: "Dirección de correo electrónico",
    confirmPassErr: "please enter confirm password",
    terms: 'Términos y condiciones',
    and: 'y',
    newLoginMsg : "Iniciar sesión con nueva contraseña",
    password15CharErr: '¡La contraseña debe tener como máximo 15 caracteres!',
    didNotGetOtp: "¿No obtuviste la OTP?",
    passwordSyntaxErr: "Password must contain 1 uppercase, 1 lowercase, 1 special character, 1 numeric digit, and be 8-15 characters long!",
    passwordNotMatchErr: "La contraseña no coincide",
    emailBoxHeader: 'Por favor ingrese su dirección de correo electrónico para continuar',
    wrongOtpEr: "Please enter a valid 6-digit OTP",
    otpExpiredErr: "¡Esta OTP ha caducado! Solicite amablemente una nueva OTP.",
    submitBtn: 'entregar',
    editEmailMsg: "Editar dirección de correo electrónico",
    language: "Idioma",
    welcomeMsg: 'Bienvenido a Paciente',
    forgotPass : "¿Has olvidado tu contraseña?",
    login : "Iniciar sesión con nueva contraseña",
    forgotPassLoginMsg : 'Por favor ingrese su dirección de correo electrónico para OTP verificación',
    verifyBtn : "Verificar",
    resetPass : "Restablecer su contraseña",
    resetPassMsg : "Por favor ingrese su nueva contraseña para restablecer la contraseña",
    passResetMsg : 'Restablecimiento de contraseña exitoso'
  },
  de: {
    pageHeader: 'Wissenschaftliche Studien prüfen Impfstoffe auf Wirksamkeit und Sicherheit',
    loginWithPass: "Mit Passwort anmelden",
    maxOtpAttemptsErr: "Maximale OTP-Versuche erreicht. Bitte versuchen Sie es nach 5 Minuten erneut",
    editEmailMsg: 'E-Mail-Adresse bearbeiten',
    otpExpiredErr: "Dieses OTP ist abgelaufen! Bitte fordern Sie ein neues OTP an.",
    confirmNewPassPh: "Bestätige neues Passwort",
    language: 'Sprache',
    setPassMsg: 'Bitte geben Sie Ihr neues Passwort ein, um es zurückzusetzen Passwort',
    invalidEmailErr: "Bitte geben Sie eine gültige Email Adresse an",
    tnc: 'Durch die Anmeldung stimmen Sie den',
    emailInputPlaceholder: "E-Mail-Adresse",
    changePassBtn: 'Kennwort ändern',
    setPass: 'Lege ein Passwort fest',
    passwordCreatedMsg: 'Passwort erfolgreich erstellt',
    submitBtn: 'Einreichen',
    didNotGetOtp: 'Sie haben das OTP nicht erhalten?',
    welcomeMsg: 'Willkommen bei Patientist',
    enterPassPh : "Passwort eingeben",
    sixDigitOtp: "Bitte geben Sie das 6-stellige OTP ein und bestätigen Sie Ihr Passwort E-Mail-Adresse",
    confirmPassErr: "please enter confirm password",
    passwordNotMatchErr: "Das Passwort stimmt nicht überein",
    continueBtnText: 'Weitermachen',
    emailBoxHeader: 'Bitte geben Sie Ihre E-Mail-Adresse ein, um fortzufahren',
    passwordSyntaxErr: 'Password must contain 1 uppercase, 1 lowercase, 1 special character, 1 numeric digit, and be 8-15 characters long!',
    passwordMatchErr: "Das Passwort stimmt nicht überein",
    wrongOtpEr: "Please enter a valid 6-digit OTP",
    resetPassMsg : "Bitte geben Sie Ihr neues Passwort ein, um das Passwort zurückzusetzen",
    pepErr: 'Bitte Passwort eingeben',
    prp: 'Datenschutzbestimmungen',
    createPasswordBtn: "Kennwort ändern",
    EnterNewPassPh: "Neues Passwort eingeben",
    resendOtp: "OTP erneut senden",
    terms: 'Allgemeinen Geschäftsbedingungen',
    and: 'und',
    unverifiedEmailErr: "Die E-Mail-Adresse wurde nicht bestätigt. Bitte geben Sie sie erneut ein",
    password15CharErr: 'Das Passwort darf maximal 15 Zeichen lang sein!',
    passResetMsg : 'Passwort erfolgreich zurückgesetzt',
    forgotPass : "Passwort vergessen?",
    login : "Anmeldung",
    newLoginMsg : "Melden Sie sich mit neuem Passwort an",
    resetPass : "Setze dein Passwort zurück",
    forgotPassLoginMsg : 'Bitte geben Sie Ihre E-Mail-Adresse für OTP ein Überprüfung',
    verifyBtn : "Verifizieren",
  },
};


// Customizable Area End
