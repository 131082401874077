Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.getStudyApiCallIdEndPoint = "bx_block_catalogue/studies/show_study";
exports.getApiMethod = "GET";
exports.getAllPatientsApi = "bx_block_catalogue/patients_studies"
exports.getAllPatientsDataApi = "bx_block_dashboard/dashboards/graph"
exports.getSinglePatientDataApi = "bx_block_dashboard/dashboards/patient_detail?account_id="
exports.getRewardsPointApi = "bx_block_dashboard/dashboards/point_reward_graph?account_id="

exports.translations = {
  fr: {
    Site_ID : "Site ID",
    Total : "Total",
    Site_Details : "Site Details",
    Points: "Points",
    Site_Coordinator : "Site Coordinator",
    Duration: "Duration",
    Study_Number : "Study Number",
    Site_Country : "Site Country",
    Dashboard : "Dashboard",
    Milestone : "Milestone",
    Milestone_Details : "Milestone Details",
    Start_Date : "Start Date",
    End_Date : "End Date",
    Site_Address : "Site Address",
    Current_Milestone : "Current Milestone",
    Patients : "Patients",
    Patients_Stats : "Patients Statistics",
    AttendedPatients : "Attended Patient according age",
    PercentageOfPatients : "Percentage of Patients",
    TaskSubmission : "Task Submission",
    NoDataFound : "No data found",
    Completed : "Completed",
    RecentlyAdded : "Recently Added Patients",
    NoPatientsFound : "No patient records have been added to display.",
    PatientId : " Patient ID",
    PointsEarned : "Points Earned",
    TotalPoints : "Total Points",
    EarnedPoints : "Earned Points",
    TaskList : "Task List",
    Attended : "Attended",
    TaskAssigned : "Task Assigned",
    TaskAttended : "Task Attended",
    VisitSchedule : "Visit Schedule",
    RewardsEarned : "Rewards Earned",

  },
  en: {
    Study_Number : "Study Number",
    Site_Coordinator : "Site Coordinator",
    Site_Country : "Site Country",
    Points: "Points",
    Milestone_Details : "Milestone Details",
    Site_Details : "Site Details",
    End_Date : "End Date",
    Dashboard : "Dashboard",
    Site_ID : "Site ID",
    Milestone : "Milestone",
    Total: "Total",
    Duration: "Duration",
    Site_Address : "Site Address",
    Start_Date : "Start Date",
    Current_Milestone : "Current Milestone",
    Patients : "Patients",
    Patients_Stats : "Patients Statistics",
    AttendedPatients : "Attended Patient according age",
    PercentageOfPatients : "Percentage of Patients",
    TaskSubmission : "Task Submission",
    NoDataFound : "No data found",
    Completed : "Completed",
    RecentlyAdded : "Recently Added Patients",
    NoPatientsFound : "No patient records have been added to display.",
    PatientId : " Patient ID",
    PointsEarned : "Points Earned",
    TotalPoints : "Total Points",
    EarnedPoints : "Earned Points",
    RewardsEarned : "Rewards Earned",
    TaskList : "Task List",
    Attended : "Attended",
    TaskAssigned : "Task Assigned",
    TaskAttended : "Task Attended",
    VisitSchedule : "Visit Schedule"

  },
  es: {
    Start_Date : "Start Date",
    Points: "Points",
    Site_Details : "Site Details",
    Milestone : "Milestone",
    Study_Number : "Study Number",
    Site_Coordinator : "Site Coordinator",
    End_Date : "End Date",
    Dashboard : "Dashboard",
    Site_Address : "Site Address",
    Total : "Total",
    Duration: "Duration",
    Site_Country : "Site Country",
    Site_ID : "Site ID",
    Milestone_Details : "Milestone Details",
    Current_Milestone : "Current Milestone",
    Patients : "Patients",
    NoPatientsFound : "No patient records have been added to display.",
    PatientId : " Patient ID",
    PointsEarned : "Points Earned",
    TotalPoints : "Total Points",
    EarnedPoints : "Earned Points",
    TaskList : "Task List",
    Attended : "Attended",
    TaskAssigned : "Task Assigned",
    TaskAttended : "Task Attended",
    Patients_Stats : "Patients Statistics",
    AttendedPatients : "Attended Patient according age",
    PercentageOfPatients : "Percentage of Patients",
    TaskSubmission : "Task Submission",
    NoDataFound : "No data found",
    Completed : "Completed",
    RecentlyAdded : "Recently Added Patients",
    VisitSchedule : "Visit Schedule",
    RewardsEarned : "Rewards Earned",

  },
  de: {
    Site_ID : "Site ID",
    Points: "Points",
    Site_Details : "Site Details",
    Duration: "Duration",
    Study_Number : "Study Number",
    Milestone : "Milestone",
    Site_Address : "Site Address",
    Total : "Total",
    End_Date : "End Date",
    Site_Country : "Site Country",
    Site_Coordinator : "Site Coordinator",
    Dashboard : "Dashboard",
    Milestone_Details : "Milestone Details",
    Start_Date : "Start Date",
    Current_Milestone : "Current Milestone",
    Patients : "Patients",
    EarnedPoints : "Earned Points",
    TaskList : "Task List",
    Attended : "Attended",
    TaskAssigned : "Task Assigned",
    TaskAttended : "Task Attended",
    Patients_Stats : "Patients Statistics",
    AttendedPatients : "Attended Patient according age",
    PercentageOfPatients : "Percentage of Patients",
    TaskSubmission : "Task Submission",
    NoPatientsFound : "No patient records have been added to display.",
    PatientId : " Patient ID",
    PointsEarned : "Points Earned",
    TotalPoints : "Total Points",
    NoDataFound : "No data found",
    Completed : "Completed",
    RecentlyAdded : "Recently Added Patients",
    VisitSchedule : "Visit Schedule",
    RewardsEarned : "Rewards Earned",

  },
};

// Customizable Area End