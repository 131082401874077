Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpPatchMethod = "PATCH";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
  exports.putApiMethod = "PUT";
exports.apiContentType = "application/json";
exports.formDataApiContentType = "multipart/form-data";
exports.getUserAccountApiEndPoint = "profile/user_profiles";
exports.getTermsAndConditionApiEndPoint = "bx_block_terms_and_conditions/terms_and_conditions"
exports.getPrivacyPolicyApiEndPoint = "bx_block_content_management/privacy_policies"
exports.getFaqsApiEndPoint = "bx_block_content_management/faqs"
exports.getContactUsApiEndPoint = "bx_block_contact_us/contacts"
exports.editUserProfileApiEnd = "bx_block_profile/profiles/update_profile"
exports.changePasswordApiEndPoint = "bx_block_profile/new_password_set"
exports.changeEmailApiEndPoint = "bx_block_profile/change_email"
exports.getStudyApiEndPoint = "bx_block_catalogue/studies"
exports.btnExampleTitle = "CLICK ME";
exports.phoneNumberRegex = /^\d{10}$/;
exports.nameRegex = /^[a-zA-Z\s]+$/;
exports.emailRegex =/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
exports.passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
// Customizable Area End
